'use client';

import { useCallback, useEffect, useState } from 'react';
import ErrorDetail, { type GlobalErrorStrings } from '@/components/ErrorDetail';
import { v4 } from 'uuid';

// no localization since this wraps the complete app (out of react context)
const strings: GlobalErrorStrings = {
  errorHeader: "This isn't right",
  message: "We're not sure what happened here. Check the URL or try again.",
  instanceId: 'Instance ID',
  time: 'Time',
  tryAgain: 'Try Again'
};

// Global error
export default function Error({ error }: { error: Error & { digest?: string } }): JSX.Element {
  const [instanceId, setInstanceId] = useState<string | undefined>(undefined);

  useEffect(() => {
    const errorId = v4();
    setInstanceId(errorId);

    const logError = async () => {
      const payload = {
        instanceId: errorId,
        error
      };
      try {
        await fetch('/venues/api/log', {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Failed to log error from global-error.tsx. Error: %o', {
          error: {
            message: e.message,
            stack: e.stack
          }
        });
      }
    };

    logError();
  }, [error]);

  const handleReset = useCallback(() => {
    window.location.reload();
  }, []);

  return <ErrorDetail reset={handleReset} translations={strings} instanceId={instanceId} />;
}
